
export default defineNuxtRouteMiddleware(async (to, from) => {
  // const user = useSupabaseUser();
  const supabase = useSupabaseClient();
  const {$toast} = useNuxtApp();
  const {
    data: { user },
  } = await supabase.auth.getUser();

  const protectedRoutes = ['manage', 'admin', 'account']
  const toPath = to.path;
  const rootPath = toPath.split("/")[1];
  const isProtected = protectedRoutes.includes(rootPath);

  if (!user && isProtected) {
    $toast.error('You need to login!');
    return navigateTo('/login')
  }
})