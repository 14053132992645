import { useMainStore } from '~/stores/main';
import { format,formatDistanceToNow } from 'date-fns';

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      capitalize: (string) => {
        if (string && typeof string === 'string') {
          const newString = string.replace(/(-|_)/g, ' ');
          return newString.charAt(0).toUpperCase() + newString.slice(1);
        }
      },

      fixed: (number) => {
        if (typeof number !== 'number') return '';
        return number.toFixed(2);
      },


      // INPUT text -> lorem impsum and some demo text that is too long
      // OUTPUT 15 day -> lorem impsum and some demo text th....
      ellipsis: (text) => {
        if (!text || typeof text !== 'string') return '';
        if(nuxtApp.$device){
          return text.length > 45 ? `${text.slice(0, 44)}...` : text;
        }
        return text.length > 67 ? `${text.slice(0, 64)}...` : text;
      },

      humanize(str) {
        let i;
        const frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
      },

      camel2title(camelCase) {
        // no side-effects
        return (
          camelCase
            // inject space before the upper case letters
            .replace(/([A-Z])/g, function (match) {
              return ' ' + match;
            })
            // replace first char with upper case
            .replace(/^./, function (match) {
              return match.toUpperCase();
            })
        );
      },

      // INPUT text -> 15 day
      // OUTPUT 15 day -> 15 days
      plural: (text) => {
        if (!text || typeof text !== 'string') return '';
        const frequency = text.split(' ')[0];
        return frequency > 1 ? `${text}s` : text;
      },

      // existingCurrencyCode is the currency code is in which the number is already
      money: (number, existingCurrencyCode) => {
        if (typeof number !== 'number') return '';
         
        const existingCurrencyCodeMapping = useMainStore().currencyMapping[existingCurrencyCode]?.rates;
        const currency = useMainStore().selectedCurrency;

        if(existingCurrencyCode && existingCurrencyCodeMapping) {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.code
          }).format(number * existingCurrencyCodeMapping[currency.code]);
        } else {
          return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.code, 
          }).format(number);
        }
      },

      dateTime:(date) => {
        return format(new Date(date), 'dd MMM yyyy, HH:mm');
      },

      timeAgo:(date) => {
        return formatDistanceToNow(new Date(date), { addSuffix: true });
      },

      date:(date) => {
        return format(new Date(date), 'dd MMM yyyy');
      },

      showLoader: (loader) => {
        useMainStore().showLoader(loader);
      },
    },
  };
});
