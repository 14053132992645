import axios from 'axios';
import { useUserStore } from '@/stores/user';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const supabase = useSupabaseClient()

  const request = axios.create({
    baseURL: `${config.public.SUPABASE_URL}/functions/v1`,
    headers: {
      Accept: 'application/json',
    },
  });

  // Add a request interceptor
  request.interceptors.request.use(async (config) => {
    const {data} = await  supabase.auth.getSession()
    if (data?.session?.access_token){
      config.headers.Authorization = `Bearer ${data?.session?.access_token}`;
    }
      return config;
  });

  return {
    provide: {
      request,
    },
  };
});
