import { default as booking_45detail_45_91id_93j4I2iFS92hMeta } from "/codebuild/output/src920068288/src/thaipass/pages/account/booking-detail-[id].vue?macro=true";
import { default as change_45passwordXipMvG52e8Meta } from "/codebuild/output/src920068288/src/thaipass/pages/account/change-password.vue?macro=true";
import { default as indexEZqFpbm295Meta } from "/codebuild/output/src920068288/src/thaipass/pages/account/index.vue?macro=true";
import { default as itineraryw9UO6s6QhCMeta } from "/codebuild/output/src920068288/src/thaipass/pages/account/itinerary.vue?macro=true";
import { default as membership_45detail_45_91id_93kG6adZ0zz8Meta } from "/codebuild/output/src920068288/src/thaipass/pages/account/membership-detail-[id].vue?macro=true";
import { default as settingsYK8ICKXGqcMeta } from "/codebuild/output/src920068288/src/thaipass/pages/account/settings.vue?macro=true";
import { default as indexPdTrXiymvOMeta } from "/codebuild/output/src920068288/src/thaipass/pages/activities/index.vue?macro=true";
import { default as _91slug_93oetmdmFKcXMeta } from "/codebuild/output/src920068288/src/thaipass/pages/activities/thailand-[location]/[slug].vue?macro=true";
import { default as indexxUX0fa8QNIMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/affiliates/index.vue?macro=true";
import { default as indexfrF6ZVPgEhMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/customers/index.vue?macro=true";
import { default as indexQb8v9WN4JBMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/index.vue?macro=true";
import { default as logstG4ClTX7gfMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/logs.vue?macro=true";
import { default as createvnAHNaAORaMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/merchants/create.vue?macro=true";
import { default as index893j13p1eLMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/merchants/index.vue?macro=true";
import { default as _91id_93VGu3EXfcYAMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/orders/[id].vue?macro=true";
import { default as index37ccq8yA2yMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/orders/index.vue?macro=true";
import { default as membership_45_91id_93PdJa0au1ZQMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/orders/membership-[id].vue?macro=true";
import { default as membershipsVWK6KeZFbdMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/orders/memberships.vue?macro=true";
import { default as refundsSmXiZRh3l8Meta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/orders/refunds.vue?macro=true";
import { default as permissionshDD50kQCUTMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/permissions.vue?macro=true";
import { default as imagespayyahWKabMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/seo/images.vue?macro=true";
import { default as create_45itinerary_45_91id_93103ZHNAICSMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/trip-planner/create-itinerary-[id].vue?macro=true";
import { default as indexP0GVimofx0Meta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/trip-planner/index.vue?macro=true";
import { default as index7h36FLmP7NMeta } from "/codebuild/output/src920068288/src/thaipass/pages/admin/users/index.vue?macro=true";
import { default as _91slug_93wJfVex7fcXMeta } from "/codebuild/output/src920068288/src/thaipass/pages/articles/[category]/[slug].vue?macro=true";
import { default as index01AYb94UYsMeta } from "/codebuild/output/src920068288/src/thaipass/pages/articles/[category]/index.vue?macro=true";
import { default as indexmIvbuqiPIJMeta } from "/codebuild/output/src920068288/src/thaipass/pages/articles/index.vue?macro=true";
import { default as booking_45informationE0YyrFEjtsMeta } from "/codebuild/output/src920068288/src/thaipass/pages/booking-information.vue?macro=true";
import { default as stripeBqxyaNFcdeMeta } from "/codebuild/output/src920068288/src/thaipass/pages/callback/stripe.vue?macro=true";
import { default as cancellationiTY6epgcgzMeta } from "/codebuild/output/src920068288/src/thaipass/pages/cancellation.vue?macro=true";
import { default as errorenbFjaGek0Meta } from "/codebuild/output/src920068288/src/thaipass/pages/error.vue?macro=true";
import { default as faqfN5VDD8RoKMeta } from "/codebuild/output/src920068288/src/thaipass/pages/faq.vue?macro=true";
import { default as forgot_45passwordOgLqBT360gMeta } from "/codebuild/output/src920068288/src/thaipass/pages/forgot-password.vue?macro=true";
import { default as _91id_93WllAElhzF6Meta } from "/codebuild/output/src920068288/src/thaipass/pages/free-thailand-itinerary/[id].vue?macro=true";
import { default as indexAUi7plQsYCMeta } from "/codebuild/output/src920068288/src/thaipass/pages/index.vue?macro=true";
import { default as offerXFHiS0FoP4Meta } from "/codebuild/output/src920068288/src/thaipass/pages/info/offer.vue?macro=true";
import { default as join_45us7dvaQDSpv7Meta } from "/codebuild/output/src920068288/src/thaipass/pages/join-us.vue?macro=true";
import { default as login8caa18x4DtMeta } from "/codebuild/output/src920068288/src/thaipass/pages/login.vue?macro=true";
import { default as createOc9RA6lrzbMeta } from "/codebuild/output/src920068288/src/thaipass/pages/manage/activities/create.vue?macro=true";
import { default as index6SQ5Hrwm1EMeta } from "/codebuild/output/src920068288/src/thaipass/pages/manage/activities/index.vue?macro=true";
import { default as add_45infoz7VR8EYBDbMeta } from "/codebuild/output/src920068288/src/thaipass/pages/manage/add-info.vue?macro=true";
import { default as dashboardE6jxpwezwbMeta } from "/codebuild/output/src920068288/src/thaipass/pages/manage/dashboard.vue?macro=true";
import { default as indexhCLw6UfYRiMeta } from "/codebuild/output/src920068288/src/thaipass/pages/manage/index.vue?macro=true";
import { default as onboard_45merchantkaA6sJ4t3FMeta } from "/codebuild/output/src920068288/src/thaipass/pages/onboard-merchant.vue?macro=true";
import { default as passR0NNDIJxssMeta } from "/codebuild/output/src920068288/src/thaipass/pages/pass.vue?macro=true";
import { default as privacy_45policyJnRL4fPZADMeta } from "/codebuild/output/src920068288/src/thaipass/pages/privacy-policy.vue?macro=true";
import { default as indexKFR7QnZ4ijMeta } from "/codebuild/output/src920068288/src/thaipass/pages/referral/index.vue?macro=true";
import { default as infoy3luhcQxOIMeta } from "/codebuild/output/src920068288/src/thaipass/pages/referral/info.vue?macro=true";
import { default as reset_45passwordmE0K5Zbi4hMeta } from "/codebuild/output/src920068288/src/thaipass/pages/reset-password.vue?macro=true";
import { default as signup_45merchant2AExJyY9eWMeta } from "/codebuild/output/src920068288/src/thaipass/pages/signup-merchant.vue?macro=true";
import { default as signupU4YMTuXwK8Meta } from "/codebuild/output/src920068288/src/thaipass/pages/signup.vue?macro=true";
import { default as supportf4ZBxeF5AgMeta } from "/codebuild/output/src920068288/src/thaipass/pages/support.vue?macro=true";
import { default as terms_45conditionsR3RxeLKWwfMeta } from "/codebuild/output/src920068288/src/thaipass/pages/terms-conditions.vue?macro=true";
import { default as thailand_45trip_45plannerQv3QRSGbfmMeta } from "/codebuild/output/src920068288/src/thaipass/pages/thailand-trip-planner.vue?macro=true";
import { default as things_45to_45do_45in_45_91location_93Qg0a0mMQZdMeta } from "/codebuild/output/src920068288/src/thaipass/pages/things-to-do-in-[location].vue?macro=true";
import { default as things_45to_45do_45in_45thailandmxKgEFaRDwMeta } from "/codebuild/output/src920068288/src/thaipass/pages/things-to-do-in-thailand.vue?macro=true";
import { default as verify_45emailKgNOtZk8UkMeta } from "/codebuild/output/src920068288/src/thaipass/pages/verify-email.vue?macro=true";
export default [
  {
    name: "account-booking-detail-id",
    path: "/account/booking-detail-:id()",
    meta: booking_45detail_45_91id_93j4I2iFS92hMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/account/booking-detail-[id].vue")
  },
  {
    name: "account-change-password",
    path: "/account/change-password",
    meta: change_45passwordXipMvG52e8Meta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/account/change-password.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexEZqFpbm295Meta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/account/index.vue")
  },
  {
    name: "account-itinerary",
    path: "/account/itinerary",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/account/itinerary.vue")
  },
  {
    name: "account-membership-detail-id",
    path: "/account/membership-detail-:id()",
    meta: membership_45detail_45_91id_93kG6adZ0zz8Meta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/account/membership-detail-[id].vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsYK8ICKXGqcMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/account/settings.vue")
  },
  {
    name: "activities",
    path: "/activities",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/activities/index.vue")
  },
  {
    name: "activities-thailand-location-slug",
    path: "/activities/thailand-:location()/:slug()",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/activities/thailand-[location]/[slug].vue")
  },
  {
    name: "admin-affiliates",
    path: "/admin/affiliates",
    meta: indexxUX0fa8QNIMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/affiliates/index.vue")
  },
  {
    name: "admin-customers",
    path: "/admin/customers",
    meta: indexfrF6ZVPgEhMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/customers/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexQb8v9WN4JBMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/index.vue")
  },
  {
    name: "admin-logs",
    path: "/admin/logs",
    meta: logstG4ClTX7gfMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/logs.vue")
  },
  {
    name: "admin-merchants-create",
    path: "/admin/merchants/create",
    meta: createvnAHNaAORaMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/merchants/create.vue")
  },
  {
    name: "admin-merchants",
    path: "/admin/merchants",
    meta: index893j13p1eLMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/merchants/index.vue")
  },
  {
    name: "admin-orders-id",
    path: "/admin/orders/:id()",
    meta: _91id_93VGu3EXfcYAMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/orders/[id].vue")
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    meta: index37ccq8yA2yMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/orders/index.vue")
  },
  {
    name: "admin-orders-membership-id",
    path: "/admin/orders/membership-:id()",
    meta: membership_45_91id_93PdJa0au1ZQMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/orders/membership-[id].vue")
  },
  {
    name: "admin-orders-memberships",
    path: "/admin/orders/memberships",
    meta: membershipsVWK6KeZFbdMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/orders/memberships.vue")
  },
  {
    name: "admin-orders-refunds",
    path: "/admin/orders/refunds",
    meta: refundsSmXiZRh3l8Meta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/orders/refunds.vue")
  },
  {
    name: "admin-permissions",
    path: "/admin/permissions",
    meta: permissionshDD50kQCUTMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/permissions.vue")
  },
  {
    name: "admin-seo-images",
    path: "/admin/seo/images",
    meta: imagespayyahWKabMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/seo/images.vue")
  },
  {
    name: "admin-trip-planner-create-itinerary-id",
    path: "/admin/trip-planner/create-itinerary-:id()",
    meta: create_45itinerary_45_91id_93103ZHNAICSMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/trip-planner/create-itinerary-[id].vue")
  },
  {
    name: "admin-trip-planner",
    path: "/admin/trip-planner",
    meta: indexP0GVimofx0Meta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/trip-planner/index.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: index7h36FLmP7NMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/admin/users/index.vue")
  },
  {
    name: "articles-category-slug",
    path: "/articles/:category()/:slug()",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/articles/[category]/[slug].vue")
  },
  {
    name: "articles-category",
    path: "/articles/:category()",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/articles/[category]/index.vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/articles/index.vue")
  },
  {
    name: "booking-information",
    path: "/booking-information",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/booking-information.vue")
  },
  {
    name: "callback-stripe",
    path: "/callback/stripe",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/callback/stripe.vue")
  },
  {
    name: "cancellation",
    path: "/cancellation",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/cancellation.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/error.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/faq.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordOgLqBT360gMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/forgot-password.vue")
  },
  {
    name: "free-thailand-itinerary-id",
    path: "/free-thailand-itinerary/:id()",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/free-thailand-itinerary/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/index.vue")
  },
  {
    name: "info-offer",
    path: "/info/offer",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/info/offer.vue")
  },
  {
    name: "join-us",
    path: "/join-us",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/join-us.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login8caa18x4DtMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/login.vue")
  },
  {
    name: "manage-activities-create",
    path: "/manage/activities/create",
    meta: createOc9RA6lrzbMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/manage/activities/create.vue")
  },
  {
    name: "manage-activities",
    path: "/manage/activities",
    meta: index6SQ5Hrwm1EMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/manage/activities/index.vue")
  },
  {
    name: "manage-add-info",
    path: "/manage/add-info",
    meta: add_45infoz7VR8EYBDbMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/manage/add-info.vue")
  },
  {
    name: "manage-dashboard",
    path: "/manage/dashboard",
    meta: dashboardE6jxpwezwbMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/manage/dashboard.vue")
  },
  {
    name: "manage",
    path: "/manage",
    meta: indexhCLw6UfYRiMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/manage/index.vue")
  },
  {
    name: "onboard-merchant",
    path: "/onboard-merchant",
    meta: onboard_45merchantkaA6sJ4t3FMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/onboard-merchant.vue")
  },
  {
    name: "pass",
    path: "/pass",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/pass.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/privacy-policy.vue")
  },
  {
    name: "referral",
    path: "/referral",
    meta: indexKFR7QnZ4ijMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/referral/index.vue")
  },
  {
    name: "referral-info",
    path: "/referral/info",
    meta: infoy3luhcQxOIMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/referral/info.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordmE0K5Zbi4hMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/reset-password.vue")
  },
  {
    name: "signup-merchant",
    path: "/signup-merchant",
    meta: signup_45merchant2AExJyY9eWMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/signup-merchant.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupU4YMTuXwK8Meta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/signup.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/support.vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/terms-conditions.vue")
  },
  {
    name: "thailand-trip-planner",
    path: "/thailand-trip-planner",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/thailand-trip-planner.vue")
  },
  {
    name: "things-to-do-in-location",
    path: "/things-to-do-in-:location()",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/things-to-do-in-[location].vue")
  },
  {
    name: "things-to-do-in-thailand",
    path: "/things-to-do-in-thailand",
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/things-to-do-in-thailand.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: verify_45emailKgNOtZk8UkMeta || {},
    component: () => import("/codebuild/output/src920068288/src/thaipass/pages/verify-email.vue")
  }
]