export const useMainStore = defineStore('main', {
  state: () => ({
    loading: false,
    cities: [],
    categories:[],
    currencies:[],
    selectedCurrency: {},
    currencyMappning: {},
    memberShip: {},
    paymentStatus : [
      'PROCESSING',
      'PENDING',
      'SUCCESS',
      'FAILED',
      'REFUNDED',
      'CHARGEBACK',
      'CANCELED',
      'EXPIRED',
      'ON_HOLD',
      'WAITING_FOR_CONFIRMATION',
      'INCOMPLETE',
      'COMPLETED',
    ],
    bookingStatus : [
      'CREATED',
      'FAILED',
      'CANCELLED',
      'SUCCESS',
      'PAYMENT_SUCCESSFUL',
      'BOOKING_PENDING',
      'BOOKING_CONFIRMED',
      'RESERVED',
      'CONFIRMED',
    ],
    membershipStatus : ['CREATED', 'SUCCESS', 'FAILED', 'EXPIRED', 'CANCELLED']
  }),
  actions: {
    showLoader(bool) {
      this.loading = bool;
    },
    setCurrency(currency){
      this.selectedCurrency = currency;
    },
   async fetchCities() {
    const supabase = useSupabaseClient();
      try{
        const { data, error } = await supabase.from('cities').select('*').order('order');
        if (error) {
          throw error;
        } else {
          this.cities = data;
        }
      }catch(e){
        console.log(e)
      }
    },
   async fetchCategories() {
      try{
        const supabase = useSupabaseClient();
        const { data, error } = await supabase.from('category').select('*').order('order');
        if (error) {
          throw error;
        } else {
          this.categories = data;
        }
      }catch(e){
        console.log(e)
      }
    },
   async fetchCurrencies() {
      try{
        const supabase = useSupabaseClient();
        const { data, error } =  await supabase.from('currency').select('*');
       
        if (error) {
          throw error;
        } else {
          this.currencies = data;
          this.currencyMappning =  data.find(currency => currency.code === 'INR').rates;
          if(!this.selectedCurrency.code){
            this.selectedCurrency = data.find(currency => currency.code === 'INR');
          }
        }
      }catch(e){
        console.log(e)
      }
    },
    async fetchActivitiesByMerchant({ from, to, cities = [], categories = [], merchantId, search = '' }) {
      try {
        const supabase = useSupabaseClient();
        let query = supabase
          .from('activities')
          .select('*,images(*)', { count: 'exact' })
          .eq('merchant_id', merchantId)
          .range(from, to);

        // Apply filters dynamically
        if (cities.length) {
          query = query.filter('city_id', 'in', `(${cities.join(',')})`);
        }

        if (categories.length) {
          query = query.filter('category_id', 'in', `(${categories.join(',')})`);
        }

        if (search) {
          query = query.textSearch('name',  `${search.split(' ').join('+')}`);
        }

        query = query.order('is_favorited', { ascending: false });

        return await query;
      } catch (e) {
        console.log(e);
      }
    },
    async fetchActivities({ from, to, cities = [], categories = [], search = '' }) {
      try {
        const supabase = useSupabaseClient();
        let query = supabase
          .from('activities')
          .select('*,images(*)', { count: 'exact' })
          .eq('active', true)
          .order('is_favorited', { ascending: false })
          .range(from, to);

        // Apply filters dynamically
        if (cities.length) {
          query = query.filter('city_id', 'in', `(${cities.join(',')})`);
        }

        if (categories.length) {
          query = query.filter('category_id', 'in', `(${categories.join(',')})`);
        }

        if (search) {
          query = query.textSearch('name',  `${search.split(' ').join('+')}`);
        }

        return await query;
      } catch (e) {
        console.log(e);
      }
    },
    async syncAllCurrencies(){
      try {
        const {$request} = useNuxtApp();
        await $request.get('/activities/syncExchangeRates');
      }catch(e){
        console.log(e)
      }
    }
  },
  getters:{
    cityMapping(state){
      return state.cities.reduce((acc, city) => {
      acc[city.id] = city;
      acc[city.slug] = city;
      return acc;
    }, {});

    },
    categoriesMapping(state){
      return state.categories.reduce((acc, category) => {
      acc[category.id] = category;
      acc[category.slug] = category;
      return acc;
    }, {});
    },
    currencyMapping(state){
      return state.currencies.reduce((acc, currency) => {
      acc[currency.id] = currency;
      acc[currency.code] = currency;
      return acc;
    }, {});
    }
},
// persist: true
});
