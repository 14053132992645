import axios from 'axios';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const blogRequest = axios.create({
    baseURL: config.public.BLOG_URL,
    headers: {
      Accept: 'application/json',
    },
  });

  return {
    provide: {
      blogRequest,
    },
  };
});
